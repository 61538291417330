<template>
  <div class="news-card" @click="detail(item.id)">
    <div class="cover">
      <img :src="item.img" />
    </div>
    <div class="info">
      <div class="title">
        <a> {{ item.title }}</a>
      </div>
      <div class="date">
        <p>{{ item.publish_time }}</p>
        <!-- <p>来源：央视体育新闻网</p> -->
      </div>
    </div>
  </div>
</template>
      
<script setup>
import { useIonRouter } from "@ionic/vue";

const props = defineProps({
  item: Object,
});

const router = useIonRouter();

const detail = (id) => {
  router.push("/content/" + id);
};
</script>
<style scoped>
.news-card {
  display: flex;
  width: 100%;
  margin: auto;
  height: 70px;
  flex-direction: row;
  background-color: white;
  /* justify-content: space-between; */
  align-content: center;
  justify-items: center;
  margin-top: 1px;
}

.news-card .info {
  display: flex;
  width: 70%;
  margin: 5px;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 10px;
}

.news-card .title {
  color: var(--unnamed, #333);
  font-size: 14px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 45%;
}

.news-card .date {
  display: flex;
  flex-direction: row;
  color: var(--999, #999);
  font-size: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 40%;
  justify-content: space-between;
  justify-content: flex-end;
}

.new-card .cover {
  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;
  width: 30%;
}

.news-card .cover img {
  width: 100px;
  height: 60px;
  margin: 5px;
  border-radius: 5px;
}
</style>