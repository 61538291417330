<template>
  <div class="menus"
    style="position: -webkit-sticky!important; position: sticky!important; top: 0px; background-color: white;">
    <p>
      <a href="/">首页</a>
    </p>
    <p>
      <a :class="type == 3 ? 'menu-sel' : ''" title="热门" href="/matches/sport-3">热门</a>
    </p>
    <p>
      <a :class="type == 4 ? 'menu-sel' : ''" title="全部" href="/matches/sport-4">全部</a>
    </p>
    <p>
      <a :class="type == 1 ? 'menu-sel' : ''" title="足球" href="/matches/sport-1">足球</a>
    </p>
    <p>
      <a :class="type == 2 ? 'menu-sel' : ''" title="蓝球" href="/matches/sport-2">蓝球</a>
    </p>

    <p @click="_side = !_side"><img src="@/assets/images/more.png" /></p>
  </div>
  <div class="side-menu" v-if="_side">
    <div class="modal"></div>
    <div class="category">
      <div class="title">
        <p>热门栏目</p>
        <p class="close" @click="_side = !_side">X</p>
      </div>
      <div class="tag">
        <a :class="type == 3 ? 'sel-category' : ''" title="热门" href="/matches/sport-3">热门</a>
        <a :class="type == 4 ? 'sel-category' : ''" title="全部" href="/matches/sport-4">全部</a>
        <a :class="type == 1 ? 'sel-category' : ''" title="足球" href="/matches/sport-1">足球</a>
        <a :class="type == 2 ? 'sel-category' : ''" title="蓝球" href="/matches/sport-2">蓝球</a>
        <a :class="type == 'NBA' ? 'sel-category' : ''" title="NBA" href="/matches/comp-NBA">NBA</a>
        <a :class="type == 'CBA' ? 'sel-category' : ''" title="CBA" href="/matches/comp-CBA">CBA</a>
        <a :class="type == '英超' ? 'sel-category' : ''" title="英超" href="/matches/comp-英超">英超</a>
        <a :class="type == '法甲' ? 'sel-category' : ''" title="法甲" href="/matches/comp-法甲">法甲</a>
        <a :class="type == '亚洲杯' ? 'sel-category' : ''" title="亚洲杯" href="/matches/comp-亚洲杯">亚洲杯</a>
        <a :class="type == '法协杯' ? 'sel-category' : ''" title="法协杯" href="/matches/comp-法协杯">法协杯</a>
        <a :class="type == '英超联' ? 'sel-category' : ''" title="英超联" href="/matches/comp-英超联">英超联</a>
        <a :class="type == '韩K联' ? 'sel-category' : ''" title="韩K联" href="/matches/comp-韩K联">韩K联</a>
        <a :class="type == '解放者杯' ? 'sel-category' : ''" title="解放者杯" href="/matches/comp-解放者杯">解放者杯</a>
        <a :class="type == '中超' ? 'sel-category' : ''" title="中超" href="/matches/comp-中超">中超</a>
        <a :class="type == '美职联' ? 'sel-category' : ''" title="美职联" href="/matches/comp-美职联">美职联</a>
        <a :class="type == '德乙' ? 'sel-category' : ''" title="德乙" href="/matches/comp-德乙">德乙</a>
        <a :class="type == '世界杯' ? 'sel-category' : ''" title="世界杯" href="/matches/comp-世界杯">世界杯</a>
        <a :class="type == '欧洲杯' ? 'sel-category' : ''" title="欧洲杯" href="/matches/comp-欧洲杯">欧洲杯</a>
        <a :class="type == '荷甲' ? 'sel-category' : ''" title="荷甲" href="/matches/comp-荷甲">荷甲</a>
        <a :class="type == '葡超' ? 'sel-category' : ''" title="葡超" href="/matches/comp-葡超">葡超</a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";

const type = ref("4");
const route = useRoute();

if (route.params.id != undefined) {
  const types = route.params.id.split("-");
  type.value = types[1];
}

const _side = ref(false);
</script>
<style scoped>
.menus {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.menus p {
  display: flex;
  width: 14%;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  color: var(--666, #666);
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.menu-sel {
  text-align: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #1890ff !important;
}

.menus img {
  width: 20px;
  height: 20px;
}

.side-menu {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
}

.side-menu .modal {
  display: flex;
  flex: 1;
  height: 100%;
  width: 50%;
  opacity: 0.5;
  background-color: #000;
}

.side-menu .category {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  background-color: white;
}

.title {
  display: flex;
  width: 100%;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 70px;
  border-bottom: 0.5px solid #ccc;
  justify-content: space-between;
}

.title p {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  margin: auto;
}

.title .close {
  position: absolute;
  top: 22px;
  right: 20px;
  font-size: 20px;
}

.tag {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  align-items: center;
  color: var(--333, #333);
  text-align: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tag a {
  display: flex;
  width: 68px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #f5f5f5;
  margin: 10px auto;
}

.tag .sel-category {
  display: flex;
  width: 68px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--unnamed, #e84b5b);
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--unnamed, #e84b5b);
  background: rgba(232, 75, 91, 0.05);
}
</style>
