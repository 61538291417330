<template>
  <!-- <div class="main-title">
    热门赛事
  </div> -->
  <div class="tags">
    <a :class="type == 3 ? 'focus' : ''" title="热门" href="/matches/sport-3">热门</a>
    <a :class="type == 4 ? 'focus' : ''" title="全部" href="/matches/sport-4">全部</a>
    <a :class="type == 1 ? 'focus' : ''" title="足球" href="/matches/sport-1">足球</a>
    <a :class="type == 2 ? 'focus' : ''" title="蓝球" href="/matches/sport-2">蓝球</a>
    <a :class="type == 'NBA' ? 'focus' : ''" title="NBA" href="/matches/comp-NBA">NBA</a>
    <a :class="type == 'CBA' ? 'focus' : ''" title="CBA" href="/matches/comp-CBA">CBA</a>
    <a :class="type == '英超' ? 'focus' : ''" title="英超" href="/matches/comp-英超">英超</a>
    <a :class="type == '法甲' ? 'focus' : ''" title="法甲" href="/matches/comp-法甲">法甲</a>
    <a :class="type == '亚洲杯' ? 'focus' : ''" title="亚洲杯" href="/matches/comp-亚洲杯">亚洲杯</a>
    <a :class="type == '法协杯' ? 'focus' : ''" title="法协杯" href="/matches/comp-法协杯">法协杯</a>
    <a :class="type == '英超联' ? 'focus' : ''" title="英超联" href="/matches/comp-英超联">英超联</a>
    <a :class="type == '韩K联' ? 'focus' : ''" title="韩K联" href="/matches/comp-韩K联">韩K联</a>
    <a :class="type == '解放者杯' ? 'focus' : ''" title="解放者杯" href="/matches/comp-解放者杯">解放者杯</a>
    <a :class="type == '中超' ? 'focus' : ''" title="中超" href="/matches/comp-中超">中超</a>
    <a :class="type == '美职联' ? 'focus' : ''" title="美职联" href="/matches/comp-美职联">美职联</a>
    <a :class="type == '德乙' ? 'focus' : ''" title="德乙" href="/matches/comp-德乙">德乙</a>
    <a :class="type == '世界杯' ? 'focus' : ''" title="世界杯" href="/matches/comp-世界杯">世界杯</a>
    <a :class="type == '欧洲杯' ? 'focus' : ''" title="欧洲杯" href="/matches/comp-欧洲杯">欧洲杯</a>
    <a :class="type == '荷甲' ? 'focus' : ''" title="荷甲" href="/matches/comp-荷甲">荷甲</a>
    <a :class="type == '葡超' ? 'focus' : ''" title="葡超" href="/matches/comp-葡超">葡超</a>
  </div>
</template>
        
<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";

const type = ref("4");
const route = useRoute();

if (route.params.id != undefined) {
  const types = route.params.id.split("-");
  type.value = types[1];
}
</script>
  
<style scoped>
.main-title {
  color: var(--333, #333);
  font-family: PingFang SC;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: white;
  padding: 10px;
}

.tags {
  display: flex;
  justify-items: center;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: white !important;
  font-size: 14px;
}

.tags::-webkit-scrollbar {
  display: none;
}

a {
  border-radius: 4px;
  color: black;
  height: 30px;
  padding: 4px 10px;
  font-size: 14px;
  margin: 5px 7px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  border-radius: 25px;
  background-color: #F1F1F1;
  padding-left: 15px;
  padding-right: 15px;
}

.focus {
  display: flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 33px;
  background-color: #3575EE;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  font-weight: 800;
}
</style>